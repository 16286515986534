.turnDeviceNotification {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
  background-image: url;
  background: url("../../images/turnDevice2.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 9999;
}

.simplepin {
  position: fixed;
  background-color: grey;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
  z-index: 1000;
}

.button {
  width: 100%;
  background-color: #ffffff00;
  border: none;
  color: white;
  display: inline-block;
  transition-duration: 0.4s;
  cursor: pointer;
  transition: -webkit-filter .3s linear;
  filter: saturate(0%);    
  -webkit-filter: saturate(0%);
}

.button:hover {
  background-color: #ffffff00;
  filter: opacity(1) drop-shadow(0 0 0 rgb(255, 157, 0));
  -webkit-filter: invert(28%) sepia(100%) hue-rotate(-45deg) saturate(12);
}

.button:after {
  background-color: #ff0000;
  filter: opacity(1);
  -webkit-filter: invert(28%) sepia(100%) hue-rotate(-185deg) saturate(4);
}

.button:active:after {
  background-color: #33ff00;
  filter: opacity(1);
  -webkit-filter: invert(28%) sepia(100%) hue-rotate(32deg) saturate(2);
}
